import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { UtilitiesService } from './utilities.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { IframeService } from './iframe-service';
declare let window: any;
declare let gapi: any;
declare var TTDUniversalPixelApi: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'Dell India\'s official store locator.';
  public tenantId: string = "1";
  public distance: number = 10000;
  public record: number = 4;
  public reviewsCount: number = 0;
 // public queryParams = ""

  // checks location on/off
  public locationOn = new BehaviorSubject<boolean>(false);
  public latitude!: number;
  public longitude!: number;
  public currentCity: string = "";
  public placeId: string = "";
  // default store listing
  public searchType: string = "s";
  public sourceName: string = "Store Locator"
  public sourceName1: string = "Store Locator Home - Request Callback"
  public sourceName2: string = "Partner Page - Request Callback"
  isLoad: boolean = false;
  locationOffOn: boolean = false
  isBrowser: boolean;
  distanceForTotalStore: number = 25
  leadForm: boolean = true;

  open : boolean = true;
  YTOriginUrl: string = "";
  consentText: any;
  constructor(private apiService: ApiService,
    private route:ActivatedRoute,
    public util: UtilitiesService, @Inject(PLATFORM_ID) platformId: Object,
    private renderer: Renderer2,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private fb : FormBuilder,
    private api : ApiService,
    private modalService : NgbModal,
    private iframeService: IframeService,
    @Inject(DOCUMENT) private document: Document ) {
    this.isBrowser = isPlatformBrowser(platformId);
  this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
      let url = event.url.split("?")[0]
      if(url == '/'){
        this.open = true;
        // this.popup(this.open);
      }else{
        this.open = false;
        this.popup(this.open);
      }
      }
    });
  }

  popUp(eve :any){
   this.popup(eve);
  }


  ngAfterViewInit(): void {
    let MARKETING_SCRIPT_PATH;
    let ANALYTICS_SCRIPT_PATH;
    if (location.hostname == 'dell-stage.hashconnect.in' || location.hostname == 'localhost') {
      ANALYTICS_SCRIPT_PATH = "https://nexus.dell.com/dell/externalDev/Bootstrap.js";
      MARKETING_SCRIPT_PATH = "https://nexus.dell.com/dell/marketingdev/Bootstrap.js";
      this.buildScript(ANALYTICS_SCRIPT_PATH,MARKETING_SCRIPT_PATH);

      this.YTOriginUrl = "https://dell-stage.hashconnect.in";
    } else {
      ANALYTICS_SCRIPT_PATH = "https://nexus.dell.com/dell/external/Bootstrap.js";
      MARKETING_SCRIPT_PATH = "https://nexus.dell.com/dell/marketing/Bootstrap.js";
      this.buildScript(ANALYTICS_SCRIPT_PATH,MARKETING_SCRIPT_PATH);
      this.YTOriginUrl = "https://dell-prod.hashconnect.in";
    }

    this.buildChatBotScript();
    //  this.onResize('');

  }

  createIframe(){
    this.iframeService.createIFrame('https://skylight.gotrackier.com/pixel?adid=6679ac5c66d0da3b6f494b95');
  }


  buildChatBotScript() {

    if (location.hostname == 'dell-stage.hashconnect.in' || location.hostname == 'localhost') {
      let id = "gs-sdk";
      let src = "//www.buildquickbots.com/botwidget/v3/demo/static/js/sdk.js?v=3";
      let key = "1ed76598-cf8f-41ae-a8c4-64e5e48330ee";
      let brand = "hashConnectUAT";
      this.buildScriptChatBot(id, src, key, brand);
    } else if
      (location.hostname == 'dellretailstores.in' || location.hostname == 'www.dellretailstores.in') {
      let id = "gs-sdk";
      let src = "//www.buildquickbots.com/botwidget/v3/demo/static/js/sdk.js?v=3";
      let key = "0af29499-df5b-4238-82e4-7f9ea49a995d";
      let brand = "hashConnectPROD";
      this.buildScriptChatBot(id, src, key, brand);

    }
  }
  buildScriptChatBot(id: string, src: string, key: string, brand: string) {
    let scriptTag: any = document.createElement('script');
    scriptTag.src = src;
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    scriptTag.id = id;
    scriptTag.setAttribute("key", key);
    scriptTag.setAttribute("brand", brand);
    document.getElementsByTagName('head')[0].appendChild(scriptTag);

  }

  buildScript(marketingScript: string, analyticsScript: string) {
    let marketing = document.createElement('script');
    marketing.src = marketingScript;
    marketing.type = 'text/javascript';
    marketing.async = true;
    document.getElementsByTagName('head')[0].appendChild(marketing);
    let analytics = document.createElement('script');
    analytics.src = analyticsScript;
    analytics.type = 'text/javascript';
    analytics.async = true;
    document.getElementsByTagName('head')[0].appendChild(analytics);
  }
  hfShow : boolean = true;

  enquiryForm!: FormGroup;


  get myForm() {
    return this.enquiryForm.controls;
  }

  description = ''
  submitText ="Submit";
  submitted : boolean = false;
  onSubmit(event :any,content : any){
    this.submitted = true
  let lead =  {
      "email":this.enquiryForm.value.email,
      "mobileNo":this.enquiryForm.value.mobileNo,
      "name":this.enquiryForm.value.name,
      "pincode":this.enquiryForm.value.pincode,
      "query":this.enquiryForm.value.query,
      "sourceName":this.enquiryForm.value.sourceName,
      "consent":this.enquiryForm.value.consent
    }
    this.loadPixelScript(this.enquiryForm.value.pincode,"");
    let sourceName = this.enquiryForm.value.sourceName
     if(this.enquiryForm.valid){
      console.log(this.enquiryForm);
      this.submitText = "Loading..."
      this.createIframe();
      this.api.storeLocatorPageLead(lead, sourceName, this.tenantId).subscribe(data =>{
        this.submitText = "Submit";
        this.popup(false);

        if (data.status == "SUCCESS") {
          this.enquiryForm.reset();
          this.open = false;
          this.submitted = false;
          this.router. navigate(['thankyou'], {
            queryParams: { username:  lead.name, thankYouPage  : 1 , leadId : data.msg},
            queryParamsHandling: 'merge',
          }); 
        } else {
          this.description = data.desc;
          this.modalService.open(content, { windowClass: "modalCls" })
        }
      },
      err =>{
        this.submitText = "Submit";
        this.submitted = false;
        console.log(err);
        
      })
     }
  }

  ngOnInit(): void {
    
    this.api.getTenantContent(this.tenantId).subscribe((data) => {
      this.consentText = data.storeLocator.consentText;
    });

    this.apiService.hfSub.subscribe( v =>{
        this.hfShow = v;
    })
    let mobile = this.deviceService.isMobile();
    let tablet = this.deviceService.isTablet();
    let desktop = this.deviceService.isDesktop();

    if (mobile) {
      window['Device'] = "m"
    }
    if (tablet) {
      window['Device'] = "t"
    }
    if (desktop) {
      window['Device'] = "d"
    }

    this.util.loading.subscribe(data => {
      this.isLoad = data
      console.log(data)
    });

      let emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
      this.enquiryForm = this.fb.group({
        name: ['', [Validators.required,Validators.minLength(2), Validators.pattern('^[^<>!1234567890]*$')]],
        email: ['', [Validators.pattern(emailPattern)]],
        mobileNo: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(10)]],
        pincode: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(6)]],
        query: [''],
        sourceName: ['STORELOCATORHOME'],
        consent: [false, Validators.requiredTrue]
      });
  }
  getDataLayerOnSearch(location:String){
    window.dataLayer.push({ 
 
      event: 'search_location', 
 
      search_query:location, 
       
            }); 
        console.log("dataLayer ", (<any>window).dataLayer);      
  }

  getDataLayervalues(store : any, type : string){

    // // (window as any)
    // if( type === 'Get_in_touch_store'){
    //   (<any>window).dataLayer.push({
    //     event: 'Get_in_touch_store', 
    //     store_name: store.displayName, 
    //     store_code:store.contractId, 
    //     store_address: store.address,   
    //     store_city : store.city.name,
    //     store_url : window.location.origin + '/' + store.formattedAddress  
    //   }) 
   
    // }else{
    //   (<any>window).dataLayer.push({
    //     event: 'Get_in_touch_product', 
    //     product_name: store.heading, 
    //     Product_description: store.text, 
    //     "Product-price": store.price,
    //     store_url: window.location.href
    //   })
    // }
    // console.log("dataLayer ", (<any>window).dataLayer);
  }

  loadPixelScript(pincode:any, searchLocation:any) {
    if (typeof TTDUniversalPixelApi === 'function') {
      // Initialize TTDUniversalPixelApi with dynamic parameters
      var universalPixelApi = new TTDUniversalPixelApi();
      universalPixelApi.init("ev5teqd", ["1tv4i71"], "https://insight.adsrvr.org/track/up", {
        "td1": pincode,
        "td2": searchLocation
      });
    }
  }



  openWhatsApp()
  {
    window.open('https://del1.in/00ez9', '_blank');
  }
  formPopup : boolean = false;
  popup(value : boolean){
    this.formPopup = value;
    setTimeout(()=>{
      this.focusOnFirstElement();
    }, 200)
  
  }

  focusOnFirstElement() {
    const element = this.document.getElementById("text-input-control-744104558") as HTMLElement;
    if (element) {
      element.focus();
    } else {
      console.warn("Element with ID 'text-input-control-744104558' not found.");
    }
  }
  

@ViewChild("chattext") chattext! :ElementRef;
@ViewChild("chatbox") chatbox! :ElementRef;
@ViewChild("closeIcon") closeIcon! :ElementRef;


@ViewChild('poBox') poBox!: ElementRef;


  onMouseEnter() {
   this.renderer.addClass(this.chattext.nativeElement,'show');
   this.renderer.setStyle(this.chatbox.nativeElement, 'background', '#FFFFFF' );
   this.renderer.setStyle(this.chatbox.nativeElement, 'box-shadow', '3px 3px 10px 0px #00000026' );
   this.renderer.setStyle(this.closeIcon.nativeElement, 'display', 'block' );
   
  }

  onMouseLeave() {
    this.renderer.removeClass(this.chattext.nativeElement,'show');
    this.renderer.setStyle(this.chatbox.nativeElement, 'background', 'none' );
    this.renderer.setStyle(this.chatbox.nativeElement, 'box-shadow', 'none' );
    this.renderer.setStyle(this.closeIcon.nativeElement, 'display', 'none' );
  }
    
openChat(){
let widgetOpen =  this.document.getElementById("widget-open");
if(widgetOpen){
  widgetOpen.click();
}
}

maxLengthTen(eve: any) {
  eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
  eve.target.value = eve.target.value.substring(0, 10)
  return false
}

maxLengthSix(eve: any) {
  eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
  eve.target.value = eve.target.value.substring(0, 6)
  return false
}

addImagePixel(pixelUrl : string){
  const img = document.createElement('img');
  img.height = 1;
  img.width = 1;
  img.style.borderStyle = 'none';
  img.alt = '';
  img.src = pixelUrl;
  const body = document.body;
  if (body.firstChild) {
    body.insertBefore(img, body.firstChild);
  } else {
    body.appendChild(img);
  }
}

callAnchorElement(href : string, target : boolean = false){
  
  const a = document.createElement('a');
  if(target){
  a.target = '_blank';
  }
  a.href = href;
  a.click();
}



maxWidth: number = 1280; // Variable to hold max-width value
cardWidth : string = '24%';
iframeHieght : number = 0;

// @HostListener('window:resize', ['$event'])
onResize(event: any) {
  const screenWidth = window.innerWidth;
  this.maxWidth =Math.floor(screenWidth * 0.90);
    this.iframeHieght = this.maxWidth * 0.50;
  if (screenWidth > 1000) {
    let calculate = 300;
    let i = 1;

    while (screenWidth > calculate) {
     calculate = calculate + 300;
      i++;
    }

    this.record = i - 1;
    this.cardWidth = `${(calculate  / i - 2)}px`;
    
  } else {
    this.maxWidth = 1280;
  }

}


chatsToggle : boolean = true;
openChatDiv() {
  const rightMoveElements = document.getElementsByClassName('right-move');
  const rightMoveOneElements = document.getElementsByClassName('right-move-1');
  const faBars = document.getElementsByClassName('fa fa-arrow-right');
  const toggleAutoplay = document.getElementById('toggleAutoplay');
  

  this.chatsToggle = !this.chatsToggle;
  if(!this.chatsToggle) {
    this.onMouseLeave();
  }
  if(toggleAutoplay) toggleAutoplay.style.zIndex =    this.chatsToggle  ? '0' : '1000';
  const translateYValue = this.chatsToggle ? '0' : '-63px';
  const translateXValue = this.chatsToggle ? '0' : '63px';

  this.applyTransformToElements(rightMoveElements, 'translateY', translateYValue );
  this.applyTransformToElements(faBars, 'translateY', translateYValue);
  this.applyTransformToElements(rightMoveOneElements, 'translateX', translateXValue);
}

applyTransformToElements(elements: HTMLCollectionOf<Element>, transformType: string, value: string) {
  for (let i = 0; i < elements.length; i++) {
    (elements[i] as HTMLElement).style.transform = `${transformType}(${value})`;
  }
}


}
