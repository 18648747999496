import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var TTDUniversalPixelApi: any;
@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private route : Router) {
    //Do nothing
  }
  socialMedia = new BehaviorSubject({});
  currentSocialMedia: Observable<any> = this.socialMedia.asObservable();

  _loading = new BehaviorSubject(false);
  loading: Observable<boolean> = this._loading.asObservable();

  storeList: EventEmitter<any> = new EventEmitter<any>();

  page = new BehaviorSubject(1);
  currentPage = this.page.asObservable();

  public getTodayStoreTimings(timings: any): any {
    let newday = JSON.parse(timings)
    const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    let weekDay = weekDays[new Date().getDay()];
    return newday[weekDay];
  }

  public getSortStoreTimings(timings: any) {

    const sorter = {
      "mon": 1,
      "tue": 2,
      "wed": 3,
      "thu": 4,
      "fri": 5,
      "sat": 6,
      "sun": 7
    }
    let newday = JSON.parse(timings)
    let days = Object.keys(newday);

    /* days.sort((a, b) => {
      let day1 = a.toLowerCase();
      let day2 = b.toLowerCase();
      //return sorter[day1] - sorter[day2];
    }); */
  }

  btnClass(type : string = 'primary', isResponsive : boolean = true ){
    if(isResponsive){
      if(type == 'primary') return 'dds__button ' + (window.innerWidth < 778 ? this.btnSize('sm') : '');
      return (window.innerWidth < 778)
        ? 'dds__button dds__button--'+ type  + ' ' + this.btnSize('sm')
        : 'dds__button dds__button--'+ type;
    
    }else{
      return (type == 'primary') ? 'dds__button ' : 'dds__button dds__button--'+ type ;
    }
   
  }

  btnSize(type : string = 'default'){
     if(type == 'default') return 'dds__button';
    return 'dds__button--'+ type;
  }

  

  maxLength(eve: any, length : number) {
    eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
    eve.target.value = eve.target.value.substring(0, length)
    return false
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  openWhatsApp()
  {
    window.open('https://del1.in/00ez9', '_blank');
  
  }

  loadPixelScript(pincode:any, searchLocation:any) {
    if (typeof TTDUniversalPixelApi === 'function') {
      // Initialize TTDUniversalPixelApi with dynamic parameters
      var universalPixelApi = new TTDUniversalPixelApi();
      universalPixelApi.init("ev5teqd", ["1tv4i71"], "https://insight.adsrvr.org/track/up", {
        "td1": pincode,
        "td2": searchLocation
      });
    }
  }

  addImagePixel(pixelUrl : string){
    const img = document.createElement('img');
    img.height = 1;
    img.width = 1;
    img.style.borderStyle = 'none';
    img.alt = '';
    img.src = pixelUrl;
    const body = document.body;
    if (body.firstChild) {
      body.insertBefore(img, body.firstChild);
    } else {
      body.appendChild(img);
    }
  }


     focusOnFirstElement(className: string): void {
      if (!className) return;
  

      const containers = document.getElementsByClassName(className);
      if (containers.length === 0) return;
      const focusableSelectors = [
          'a[href]',
          'button:not([disabled])',
          'input:not([disabled]):not([type="hidden"])',
          'select:not([disabled])',
          'textarea:not([disabled])',
          '[tabindex]:not([tabindex="-1"])'
      ];
  
      const firstContainer = containers[0];
      const focusableElements = firstContainer.querySelectorAll(focusableSelectors.join(','));
  
      if (focusableElements.length > 0) {
          (focusableElements[0] as HTMLElement).focus();
      }
  }
  

  focusFirstInvalidField(formGroup: FormGroup): void {
    const invalidControl = Object.keys(formGroup.controls).find(control => 
      formGroup.get(control)?.invalid
    );
  
    if (invalidControl) {
      const formControlElement = document.querySelector(`[formControlName="${invalidControl}"]`);
      if (formControlElement) {
        (formControlElement as HTMLElement).focus();
      }
    }
  }


  goBack() {
    if(environment.production){
        this.route.navigate(["/"], { queryParamsHandling: "preserve" });
        return;
    }
    window.location.href = "/"
  }
}
