<div class="stTopsection" *ngIf="storDetails">
    <div class="sttLeft">
        <div class="lcHead">
            <div class="d-flex flex-column flex-md-row align-items-start">
                <h2 class="mb-0" style="margin-right: 14px;">{{storDetails.displayName}}</h2>
                <button *ngIf="storDetails.status._key === 'TEMPORARILY_CLOSED'" class="temp-closed btn btn-warning mb-2 mb-md-0 ml-md-3">
                  Temporarily Closed
                </button>
               
              </div>
              
              
            <p>
                {{storDetails.address}}
            </p>
        </div>
        <div class="mobileSlider bigarrow">
            <div class="storePhotosMobile" *ngIf="storDetails.partnerImages && storDetails.partnerImages.length>0">
                <div class="spmItems" *ngFor="let si of storDetails.partnerImages; let i=index;">
                    <img [src]="si.location" alt="store Photo" />
                </div>
            </div>
        </div>
        <div class="storeAdress">
            <h3>About Store</h3>
            <p>
                {{storDetails.aboutStore}}
            </p>
        </div>
        <ol class="sdListItems iconLeft">
            <li *ngIf="storDetails.email != null && storDetails.email != ' ' ">
                <a href="mailto:{{storDetails.email}}"  class="email">{{storDetails.email}}</a>
            </li>
            <li>
                <a class="clock">
                    <em>Open Now </em>
                    <span>{{utils.getTodayStoreTimings(storDetails.timings)}} (Today)
                    </span>
                </a>
            </li>
            <li>
                <a [attr.href]="storDetails.gmapLink" target="_blank" class="getDirection-new">Get
                    Directions</a>
            </li>
        </ol>
        <div class="d-flex justify-content-start align-items-center gap-3">
           
            <a href="tel:{{storDetails.phoneNumber}}" [attr.disabled]="storDetails.status._key === 'TEMPORARILY_CLOSED' ? true : null"
            [class.disabled]="storDetails.status._key === 'TEMPORARILY_CLOSED' ? true : null"  tabindex="0" role="button"  class="botn" [ngClass]="utils.btnClass()"
            (click)="app.getDataLayervalues(storDetails, 'Get_in_touch_store')">Get In Touch - {{storDetails.phoneNumber}}</a>

        <button [attr.disabled]="storDetails.status._key === 'TEMPORARILY_CLOSED' ? true : null"
        [class.disabled]="storDetails.status._key === 'TEMPORARILY_CLOSED' ? true : null" class="botn" [ngClass]="utils.btnClass('secondary')" tabindex="0" (click)="requestCallBackClick();app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:n1qrllz&fmt=3')"> 
            Request Call Back</button>
        </div>

    </div>
    <div class="sttRight">
        <div class="shareDistance">
            <span class="sharewrap" (click)="sharemobiel()"> Share 
            </span>
        </div>
        <div class="photoGalleryDesktop" *ngIf="storDetails.partnerImages && storDetails.partnerImages.length>0">
            <h4>Store Photo Gallery</h4>
            <!-- <div class="pgCoverImage">
                <div *ngFor="let si of storDetails.partnerImages; let i=index;" class="pgItem" [id]="'pg'+(i+1)"
                    [ngStyle]="i===0?{'display': 'block'}:{}">
                    <img [src]="si.location" alt="" />
                </div>
            </div>
            <ul class="pgListToggle">
                <li *ngFor="let si of storDetails.partnerImages; let i=index;">
                    <img [src]="si.location" alt="" (click)="toggleImage($event,i)" [attr.data-href]="'pg'+(i+1)"
                        [ngClass]="i===0?'active':''" />
                </li>
            </ul> -->
            <div class="pgCoverImage" id="main-slider">
                <div *ngFor="let si of storDetails.partnerImages; let i=index;" class="pgItem">
                  <img [src]="si.location" alt="Image {{i+1}}" />
                </div>
              </div>
              
              <ul class="pgListToggle" id="thumbnail-slider">
                <li *ngFor="let si of storDetails.partnerImages; let i=index;">
                  <img [src]="si.location" alt="Thumbnail {{i+1}}" />
                </li>
              </ul>
              
        </div>
    </div>
</div>